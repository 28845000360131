export default {
    methods: {
//숫자필터
        filterNonNumeric(value) {
            return value.replace(/[^0-9]/g, '');
        },
        //사업자 검사
        checkBusinessNumber(str){
            var regExp = /^[0-9]{3}-[0-9]{2}-[0-9]{5}$/;
            if(regExp.test(str)) {
              return true
            } else {
              return false
            }
        },
        //핸드폰 검사
        checkPhone(str) {
            var regExp = /01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}/;
            if (regExp.test(str)) {
                return true
            } else {
                return false
            }
        },
        //이메일 검사
        checkEmail(str) {
            var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
            if (regExp.test(str)) {
                return true;
            } else {
                return false;
            }
        },
        //특문 검사
        checkSpecial(str) {
            const regExp = /[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi;
            if (regExp.test(str)) {
                return true;
            } else {
                return false;
            }
        },
        //한글 체크
        checkKor(str) {
            const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
            if (regExp.test(str)) {
                return true;
            } else {
                return false;
            }
        },
        // 숫자 체크
        checkNum(str) {
            const regExp = /[0-9]/g;
            if (regExp.test(str)) {
                return true;
            } else {
                return false;
            }
        },
        // 영문(영어) 체크
        checkEng(str) {
            const regExp = /[a-zA-Z]/g; // 영어
            if (regExp.test(str)) {
                return true;
            } else {
                return false;
            }
        },
    }
}
