import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import globalMixins from './mixin/globals'
import dayjs from "dayjs";
import Slick from 'vue-slick';

Vue.mixin({
  created() {
    this.$dayjs = dayjs;
  }
})

Vue.use(globalMixins)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Slick)

Vue.config.productionTip = false

const pinia = createPinia()
Vue.use(pinia)

new Vue({
  router: router,
  render: h => h(App),
  pinia,
}).$mount('#app')
