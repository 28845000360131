import {JwtAxios} from "@/packaging";

export const accountAPI = {
    async Login(model){
        const responseModel = await JwtAxios.post(`/Account/Login`,model);
        return responseModel
    },

    async SignUp(model){
        const responseModel = await JwtAxios.post(`/Account/SignUp`,model);
        return responseModel
    },
    async UserUpdate(model){
        const responseModel = await JwtAxios.post(`/Account/UserUpdate`,model);
        return responseModel
    },
    async GetUser(){
        const responseModel = await JwtAxios.get(`/Account/GetUser`);
        return responseModel
    },
    async UserDelete(model){
        const responseModel = await JwtAxios.post(`/Account/UserDelete`, model);
        return responseModel
    },
}