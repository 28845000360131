import {JwtAxios} from "@/packaging";
import dayjs from "dayjs";

export const dbAPI = {
    async GetData(type, page,limit=15,startDate="",endDate="", region="",keyword=""){
        if(startDate=="" || endDate =="") {
            startDate='1990-01-01'
            endDate= dayjs().format('YYYY-MM-DD')
        }
        const responseModel = await JwtAxios.get(`/DB/GetData?type=${type}&page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&region=${region}&keyword=${keyword}`);
        return responseModel
    },
    async GetSearchData(type, page, keyword=""){
        const responseModel = await JwtAxios.get(`/DB/GetSearchData?type=${type}&page=${page}&keyword=${keyword}`);
        return responseModel
    },
    async GetSummary(){
        const responseModel = await JwtAxios.get(`/DB/GetSummary`);
        return responseModel
    },
    async GetSummaryData(){
        const responseModel = await JwtAxios.get(`/DB/GetSummaryData`);
        return responseModel
    },
    async ExportExcel(model){
        const responseModel = await JwtAxios.download(`/DB/ExportExcel`,model);
        return responseModel
    },
}