<template>
  <div>
    <section class="footer-term-sec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="term-col">
              <router-link to="/term1">서비스 이용약관</router-link>
              <router-link to="/term2">개인정보처리방침</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="representative-sec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="representative-col">
              <div class="representative-img">
                <img src="/assets/images/footer-logo.png" alt="">
              </div>
              <div class="representative-content">
                <h6>
                  대표 김태민　|　사업자번호 517-22-051566　|　통신판매업신고번호 2022-서울관악-0935
                  <br>
                  개인정보책임자 김태민　|　서울특별시 강서구 공항대로 242, 2층 211호
                </h6>
                <p>
                  Copyright © 2024 DB매니저 | Powered by DB매니저 | Designed by Icons 8 from Ouch!​
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>