<template>
  <b-modal ref="loginModal" hide-footer hide-header>
    <div class="login-modal">
      <div class="title">
        <h3>로그인</h3>
      </div>
      <div>
        <label>아이디</label><b-input ref="account" :readonly="isCheck" @focusin="isCheck=false"  v-model="loginModel.account" placeholder="아이디를 입력해 주세요."/>
        <label>비밀번호</label><b-input :readonly="isCheck" @focusin="isCheck=false"   @keyup.enter="save"  type="password" v-model="loginModel.password" placeholder="비밀번호를 입력해 주세요."/>
      </div>
      <div class="subOption">
        <b-check v-model="loginModel.isSave">기억하기</b-check>
        <a>비밀번호 잊어버리셨나요?</a>
      </div>
      <div>
        <b-button @click="save" variant="success" class="mt-2 w-100 text-center login-btn">로그인</b-button>
      </div>
      <div class="divider">
        <div class="divider-content">
          <span>or</span>
        </div>
      </div>
      <div>
        <b-button variant="success" href="./signup" class="mt-2 w-100 text-center join-btn">회원가입</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import {accountAPI} from "@/api";
export default {
  name: "LoginModal",
  props:['loginInit'],
  data(){
    return {
      isCheck: true,
      loginModel:{
        account:'',
        password:'',
        isSave:false
      },
    }
  },
  methods:{
    async save(){
      const userModel = await this.authStore.login(this.loginModel.account, this.loginModel.password, this.loginModel.isSave)
      if(userModel.success == true){
        this.loginInit()
        this.close()
      }
      else {
        alert(userModel.errorMessage)
      }
    },
    open(){
      this.isCheck = true
      this.loginModel = {
        account:localStorage.getItem('saveId'),
        password:'',
        isSave:localStorage.getItem('saveId') != null ? true : false
      }
      this.$refs.loginModal.show()
      setTimeout(() => {
        this.$refs.account.focus();
      }, 200);
    },
    close(){
      this.$refs.loginModal.hide()
    }
  }

}
</script>

<style scoped>
.form-control[readonly] {
  background: white;
}
</style>