<template>
  <nav class="navbar navbar-expand-lg navbar-light nav-sec">
    <div class="container">
      <router-link to="/">
        <a class="navbar-brand" href="#">
          <img src="/assets/images/logo.png" style="cursor: pointer" alt="">
        </a>
      </router-link>
      <button class="navbar-toggler" v-b-toggle.navbarSupportedContent>
        <span class="navbar-toggler-icon"></span>
      </button>
      <b-collapse class="navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li v-if="isLogin == false" class="nav-item">
            <a href="#" class="nav-link" :class="{'active' : isLogin == false}" @click="loginModalOpen">로그인</a>
          </li>
          <li v-else class="nav-item">
            <a href="#" class="nav-link" :class="{'active' : isLogin == true}" @click="logout">로그아웃</a>
          </li>
          <li v-if="isLogin == false" class="nav-item">
            <router-link to="/signup" class="nav-link">
              회원가입
            </router-link>
          </li>
          <li v-else class="nav-item">
            <router-link to="/mypage" class="nav-link">
              마이페이지
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/notice" class="nav-link">
              고객센터
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/license" class="nav-link">
              이용권 구매
            </router-link>
          </li>
        </ul>
      </b-collapse >
    </div>
    <loginModal :loginInit="loginInit" ref="loginModal"/>
  </nav>
</template>

<script>
import loginModal from './Modals/LoginModal'

export default {
  name: "Hearder",
  components:{
    loginModal
  },
  data(){
    return {
      isLogin: false,
      loginModel : null
    }
  },
  async created() {
    await this.loginInit()
  },
  watch: {
    async user(data){
      if(data == false){
        this.isLogin = false
      }
    }
  },
  methods:{
    logout(){
      this.authStore.logout()
      this.isLogin = false
    },
    async loginInit(){
      var setupUserModel = await this.authStore.setupUser()
      if(setupUserModel != undefined && setupUserModel != null) {
        this.isLogin = true
        this.loginModel = setupUserModel
      }
    },
    loginModalOpen(){
      this.$refs.loginModal.open()
    }
  }
}
</script>

<style scoped>
.small-font{
  font-size: 13px;
}
.normal-font {
  font-size: 16px;
}
</style>