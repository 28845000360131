import {JwtAxios} from "@/packaging";

export const smsAPI = {
    async SendSMS(token){
        const responseModel = await JwtAxios.get(`/Sms/SendSMS?token=${token}`);
        return responseModel
    },

    async SetToken(phone){
        const responseModel = await JwtAxios.post(`/Sms/SetToken?phoneNumber=${phone}`,'');
        return responseModel
    },
    async CheckSMS(token,tokenValue){
        const responseModel = await JwtAxios.get(`/Sms/CheckSMS?token=${token}&tokenValue=${tokenValue}`);
        return responseModel
    }
}